// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-yhteydenotosta-js": () => import("./../src/pages/kiitos-yhteydenotosta.js" /* webpackChunkName: "component---src-pages-kiitos-yhteydenotosta-js" */),
  "component---src-pages-tietoa-kohteesta-js": () => import("./../src/pages/tietoa-kohteesta.js" /* webpackChunkName: "component---src-pages-tietoa-kohteesta-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

